import React, { Component } from 'react';
import bgimage from './Pic/bg1.jpg';
import { motion } from 'framer-motion';
import Particles from 'react-particles-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBMask, MDBView, MDBTypography, MDBBox, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dg from './Pic/Diagram.png';

import Cimg1 from './Pic/ImgLegal/IUKonstruksi.jpg';
import Cimg2 from './Pic/ImgLegal/IUPerdagangan.jpg';
import Cimg3 from './Pic/ImgLegal/IUKonstruksi1.jpg';
import Cimg4 from './Pic/ImgLegal/IUKonstruksi2.jpg';
import Cimg5 from './Pic/ImgLegal/NIB.jpg';
import Cimg6 from './Pic/ImgLegal/NIB2.jpg';
import Cimg7 from './Pic/ImgLegal/SIUP.jpg';
import Cimg8 from './Pic/ImgLegal/TDP.jpg';
import Cimg9 from './Pic/ImgLegal/Domisili.jpg';
import Cimg10 from './Pic/ImgLegal/SKT.jpg';
import Cimg11 from './Pic/ImgLegal/DokPengesahan.jpg';
import Cimg12 from './Pic/ImgLegal/RekDamkar.jpg';
import Cimg13 from './Pic/ImgLegal/Sertifikat1.jpg';
import Cimg14 from './Pic/ImgLegal/RincianKlasifikasi.jpg';
import Cimg15 from './Pic/ImgLegal/npwp.jpg';


class JumAbout extends Component {
    render() { 
    return (
      <motion.div
      initial={{ opocity:0, y:"-100" }}
      animate={{ opocity:2 }}
      exit={{ opocity:0 }}>
            <div className="App">
            <MDBView>
            <Particles
                          params={{
                                  "particles": {
                                      "number": {
                                          "value": 60,
                                          "density": {
                                              "enable": true,
                                              "value_area": 1500
                                          }
                                      },
                                      "line_linked": {
                                          "enable": true,
                                          "opacity": 0.02
                                      },
                                      "move": {
                                          "direction": "right",
                                          "speed": 0.05
                                      },
                                      "size": {
                                          "value": 2
                                      },
                                      "opacity": {
                                          "anim": {
                                              "enable": true,
                                              "speed": 1,
                                              "opacity_min": 0.05
                                          }
                                      }
                                  },
                                  "interactivity": {
                                      "events": {
                                          "onclick": {
                                              "enable": true,
                                              "mode": "push"
                                          }
                                      },
                                      "modes": {
                                          "push": {
                                              "particles_nb": 0.5
                                          }
                                      }
                                  },
                                  "retina_detect": true
                              }}                         
                              style={{
                                backgroundImage: `url(${bgimage}`}} />
                                
                      <MDBMask overlay="black-light" className="flex-center flex-column text-white text-center">
                        <span className="block-example border border-white">
                        <h1  style={{fontWeight:"bold", marginRight:"10px"}}> 
                        <FontAwesomeIcon icon={faAddressBook} size="1x" style={{marginRight:"10px" , marginLeft:"10px"}} />
                           ABOUT US</h1>
                        </span>
                    </MDBMask>
              </MDBView>

              <div class="container" style={{marginTop:'-30px', marginBottom:'1px', backgroundColor:"whitesmoke", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                <div class="row">
                  <div class="col-md-12 info-panel">

                  <MDBTypography blockquote bqColor='primary'>
                    <MDBBox tag='p' mb={0} className='bq-title'> 
                    About Me
                    </MDBBox>
                        <p style={{color:'black', fontSize:'14px'}}>
                            <strong>PT. Ori Nuansa Teknologi</strong> has been operating since 2018, our work covers all aspects of the work in the field of Mechanical installation 
                            including Manufacturing and Modification of Factory Vent Chimney, Making Loading Dock and Container Parking, Asphalt and Road Repair, 
                            Making the Jakarta-Bandung fast train access project, Fire Alarm System and Hidrant installation <br></br>
                            <br></br>
                            During this time our services have become specialists in planned preventative maintenance and Installation.
                            Our success is based on an ongoing commitment of service provided to all our clients by continuously updating and developing our services and, importantly, at the present time rising costs-competitive prices<br></br>
                            <br></br> The consistency of the quality of our work, experienced mechanics, civil and electricity, reliable 24-hour outgoing service, and preventative maintenance
                            We are also committed to always maintaining Health and Safety for all our engineers fully trained in this field. We also use external companies to monitor and audit our health and safety practices
                        </p>
                    </MDBTypography>

                    <MDBTypography blockquote bqColor='primary'>
                    <MDBBox tag='p' mb={0} className='bq-title'> 
                    Core Bussiness
                    </MDBBox>
                        <p style={{color:'black', fontSize:'14px', fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                            <dl className="row">
                                <dt className="col-sm-3">Job Specialist </dt>
                                <dd className="col-sm-9">Mechanical Engineering, Road Contruction and Electrical System.</dd>

                                <dt className="col-sm-3">Employee   </dt>
                                <dd className="col-sm-9">38 Persons.</dd>
                                <dd className="col-sm-9 offset-sm-3">Administration Management 8 Persons</dd>
                                <dd className="col-sm-9 offset-sm-3">Technician 30 Persons </dd>

                                <dt className="col-sm-3">Land Area  </dt>
                                <dd className="col-sm-9">1000 m2.</dd>

                                <dt className="col-sm-3 text-truncate">Build Area</dt>
                                <dd className="col-sm-9">120 m2.</dd>

                                <dt className="col-sm-3">Total Asset</dt>
                                    <dd className="col-sm-8">Rp, 2.000.000.000.-</dd>
                            </dl>
                        </p>
                    </MDBTypography>

                  </div>
                </div>
              </div>

              <div class="container" style={{marginTop:'1px', marginBottom:'50px', backgroundColor:"whitesmoke", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                <div class="row">
                  <div class="col-md-12 info-panel">
                    <MDBTypography blockquote bqColor='success'>
                        <MDBBox tag='p' mb={0} className='bq-title'> 
                                Organization Structure
                        </MDBBox>  
                    
                    <MDBContainer>
                    <MDBRow >
                        <MDBCol> 
                        <img src={Dg} className="img-fluid z-depth-1 mx-auto d-block" style={{height:'490px', width:'854px'}} alt=""/>
                        </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    </MDBTypography> 
                </div>
            </div>
        </div>

        <div class="container" style={{marginTop:'1px', marginBottom:'50px', backgroundColor:"whitesmoke", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                <div>
                  <div>
                    <MDBTypography blockquote bqColor='warning'>
                        <MDBBox tag='p' mb={0} className='bq-title'> 
                                Company Legalization Documents
                        </MDBBox>  
                        <MDBContainer>
                            <MDBRow style={{marginBottom:'50px'}}>
                            <MDBCol lg="6">
                                <MDBView waves>
                                    <h5 class="text-center"> Construction Services Business License</h5>
                                    <MDBCol>
                                    <img src={Cimg1} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            <MDBCol lg="6">
                                <MDBView waves>
                                     <h5 class="text-center"> Trading Business License</h5>
                                    <MDBCol>
                                    <img src={Cimg2} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBTypography> 
                </div>
            </div>
        </div>
        
        <div class="container" style={{marginTop:'1px', marginBottom:'50px', backgroundColor:"whitesmoke", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                <div>
                  <div>
                    <MDBTypography blockquote bqColor='warning'>
                        <MDBBox tag='p' mb={0} className='bq-title'> 
                                Company Legalization Documents
                        </MDBBox>  
                        <MDBContainer>
                            <MDBRow style={{marginBottom:'50px'}}>
                            <MDBCol lg="6">
                                <MDBView waves>
                                    <h5 class="text-center">Water Channel Construction Services Business License</h5>
                                    <MDBCol>
                                    <img src={Cimg3} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            <MDBCol lg="6">
                                <MDBView waves>
                                     <h5 class="text-center">Building Construction Services Business License</h5>
                                    <MDBCol>
                                    <img src={Cimg4} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBTypography> 
                </div>
            </div>
        </div>
        <div class="container" style={{marginTop:'1px', marginBottom:'50px', backgroundColor:"whitesmoke", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                <div>
                  <div>
                    <MDBTypography blockquote bqColor='warning'>
                        <MDBBox tag='p' mb={0} className='bq-title'> 
                                Company Legalization Documents
                        </MDBBox>  
                        <MDBContainer>
                            <MDBRow style={{marginBottom:'50px'}}>
                            <MDBCol lg="6">
                                <MDBView waves>
                                    <h5 class="text-center">Main Business Number</h5>
                                    <MDBCol>
                                    <img src={Cimg5} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            <MDBCol lg="6">
                                <MDBView waves>
                                     <h5 class="text-center">Main Business Number</h5>
                                    <MDBCol>
                                    <img src={Cimg6} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBTypography> 
                </div>
            </div>
        </div>
        <div class="container" style={{marginTop:'1px', marginBottom:'50px', backgroundColor:"whitesmoke", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                <div>
                  <div>
                    <MDBTypography blockquote bqColor='warning'>
                        <MDBBox tag='p' mb={0} className='bq-title'> 
                                Company Legalization Documents
                        </MDBBox>  
                        <MDBContainer>
                            <MDBRow style={{marginBottom:'50px'}}>
                            <MDBCol lg="6">
                                <MDBView waves>
                                    <h5 class="text-center">SIUP</h5>
                                    <MDBCol>
                                    <img src={Cimg7} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            <MDBCol lg="6">
                                <MDBView waves>
                                     <h5 class="text-center">TDP</h5>
                                    <MDBCol>
                                    <img src={Cimg8} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBTypography> 
                </div>
            </div>
        </div>
        <div class="container" style={{marginTop:'1px', marginBottom:'50px', backgroundColor:"whitesmoke", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                <div>
                  <div>
                    <MDBTypography blockquote bqColor='warning'>
                        <MDBBox tag='p' mb={0} className='bq-title'> 
                                Company Legalization Documents
                        </MDBBox>  
                        <MDBContainer>
                            <MDBRow style={{marginBottom:'50px'}}>
                            <MDBCol lg="6">
                                <MDBView waves>
                                    <h5 class="text-center">Domicile</h5>
                                    <MDBCol>
                                    <img src={Cimg9} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            <MDBCol lg="6">
                                <MDBView waves>
                                     <h5 class="text-center">SKT</h5>
                                    <MDBCol>
                                    <img src={Cimg10} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBTypography> 
                </div>
            </div>
        </div>
        <div class="container" style={{marginTop:'1px', marginBottom:'50px', backgroundColor:"whitesmoke", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                <div>
                  <div>
                    <MDBTypography blockquote bqColor='warning'>
                        <MDBBox tag='p' mb={0} className='bq-title'> 
                                Company Legalization Documents
                        </MDBBox >  
                        <MDBContainer>
                            <MDBRow style={{marginBottom:'50px'}}>
                            <MDBCol lg="6">
                                <MDBView waves>
                                    <h5 class="text-center">Ratification document</h5>
                                    <MDBCol>
                                    <img src={Cimg11} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            <MDBCol lg="6">
                                <MDBView waves>
                                     <h5 class="text-center">Fire Fighting Recommendations</h5>
                                    <MDBCol>
                                    <img src={Cimg12} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'500px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBTypography> 
                </div>
            </div>
        </div>
        <div class="container" style={{marginTop:'1px', marginBottom:'50px', backgroundColor:"whitesmoke", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                <div>
                  <div>
                    <MDBTypography blockquote bqColor='warning'>
                        <MDBBox tag='p' mb={0} className='bq-title'> 
                                Company Legalization Documents
                        </MDBBox>  
                        <MDBContainer>
                            <MDBRow style={{marginBottom:'50px'}}>
                            <MDBCol lg="12">
                                <MDBView waves>
                                    <h5 class="text-center">Certificate of Business Entity Construction Implementation Services</h5>
                                    <MDBCol>
                                    <img src={Cimg13} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'1000px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBTypography> 
                </div>
            </div>
        </div>
        <div class="container" style={{marginTop:'1px', marginBottom:'50px', backgroundColor:"whitesmoke", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                <div>
                  <div>
                    <MDBTypography blockquote bqColor='warning'>
                        <MDBBox tag='p' mb={0} className='bq-title'> 
                                Company Legalization Documents
                        </MDBBox>  
                        <MDBContainer>
                            <MDBRow style={{marginBottom:'50px'}}>
                            <MDBCol lg="12">
                                <MDBView waves>
                                    <h5 class="text-center">Details of the Classification and Qualification of the Construction Services Business Entity</h5>
                                    <MDBCol>
                                    <img src={Cimg14} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'700px', width:'1000px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBTypography> 
                </div>
            </div>
        </div>
        <div class="container" style={{marginTop:'1px', marginBottom:'50px', backgroundColor:"whitesmoke", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
                <div>
                  <div>
                    <MDBTypography blockquote bqColor='warning'>
                        <MDBBox tag='p' mb={0} className='bq-title'> 
                                Company Legalization Documents
                        </MDBBox>  
                        <MDBContainer>
                            <MDBRow style={{marginBottom:'50px'}}>
                            <MDBCol lg="12">
                                <MDBView waves>
                                    <h5 class="text-center">NPWP</h5>
                                    <MDBCol>
                                    <img src={Cimg15} className="img-fluid z-depth-1 mx-auto d-block"  style={{height:'400px', width:'600px'}} alt="" />
                                    </MDBCol>
                                </MDBView>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBTypography> 
                </div>
            </div>
        </div>
          </div> 
        </motion.div>
    );
  }
}
  export default JumAbout;