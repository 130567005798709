import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardGroup,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBContainer,
  MDBRow
} from 'mdbreact';
import Cimg1 from './Pic/Road/img1.jpeg';
import Cimg2 from './Pic/Road/img2.jpeg';
import Cimg3 from './Pic/Road/img3.jpeg';
import Cimg4 from './Pic/Road/img4.jpeg';
import Cimg5 from './Pic/Road/img5.jpeg';
import Cimg6 from './Pic/Road/img6.jpeg';
import Cimg7 from './Pic/Road/img7.jpg';
import Cimg8 from './Pic/Road/img8.jpg';
import Cimg9 from './Pic/Road/img9.jpg';
import './CardPage.css';
import Fade from 'react-reveal/Fade'; // Importing Fade effect


const CardsPage = () => {
  return (
    <MDBContainer class="Card-Image">
        <MDBCardGroup deck className='mt-3'>
        <MDBRow>
          <MDBCard>
            <Fade left>
            <MDBCardImage
              src={Cimg1}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Road Contruction</MDBCardTitle>
              <MDBCardText>
              Jakarta-Bandung High Speed ​​Railway Road Access Project (Karawang Station).
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg2}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Road Contruction</MDBCardTitle>
              <MDBCardText>
              Jakarta-Bandung High Speed ​​Railway Road Access Project (Karawang Station).
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg3} 
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Road Contruction</MDBCardTitle>
              <MDBCardText>
              Jakarta-Bandung High Speed ​​Railway Road Access Project (Karawang Station).
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>

        <MDBCardGroup deck className='mt-3'>
          <MDBRow>
          <MDBCard>
          <Fade left>
            <MDBCardImage
              src={Cimg4}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Road Contruction</MDBCardTitle>
              <MDBCardText>
              Jakarta-Bandung High Speed ​​Railway Road Access Project (Karawang Station).
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg5}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Road Contruction</MDBCardTitle>
              <MDBCardText>
              Jakarta-Bandung High Speed ​​Railway Road Access Project (Karawang Station).
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg6}
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Road Contruction</MDBCardTitle>
              <MDBCardText>
              Jakarta-Bandung High Speed ​​Train Access Project (DK-106 Padalarang).
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>

        <MDBCardGroup deck className='mt-3'>
          <MDBRow>
          <MDBCard>
          <Fade left>
            <MDBCardImage
              src={Cimg7}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Road Contruction</MDBCardTitle>
              <MDBCardText>
              Jakarta-Bandung High Speed ​​Train Access Project (DK-106 Padalarang).
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg8}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Road Contruction</MDBCardTitle>
              <MDBCardText>
              Jakarta-Bandung High Speed ​​Train Access Project (DK-106 Padalarang).
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg9}
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Road Contruction</MDBCardTitle>
              <MDBCardText>
              Jakarta-Bandung High Speed ​​Train Access Project (DK-106 Padalarang).
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>
    </MDBContainer>
  );
};
export default CardsPage;
