import React, { Component } from 'react';
import './JumHome.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Panel1 from './Pic/bc.png';
import Panel2 from './Pic/ec.png';
import Panel3 from './Pic/ic.png';
import Panel4 from './Pic/el.png';
import ws from './Pic/Our-Mission.png';
import bgimage from './Pic/bg2.jpg';

import CardsPage1 from './CardsPage.js';
import CardsPage2 from './CardsPage2.js';
import CardsPage3 from './CardsPage3.js';
import CardsPage4 from './CardsPage4.js';
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route
} from "react-router-dom";
import Particles from 'react-particles-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBMask, MDBView} from 'mdbreact';
import { faHome,} from '@fortawesome/free-solid-svg-icons';
import '../index.css';

class JumHome extends Component {
      render() {
        return(
           
      <Router>
              <MDBView>
              <div style={{position: 'relative'}}>
              <Particles
                          params={{
                                  "particles": {
                                      "number": {
                                          "value": 60,
                                          "density": {
                                              "enable": true,
                                              "value_area": 1500
                                          }
                                      },
                                      "line_linked": {
                                          "enable": true,
                                          "opacity": 0.02
                                      },
                                      "move": {
                                          "direction": "right",
                                          "speed": 0.05
                                      },
                                      "size": {
                                          "value": 1.2
                                      },
                                      "opacity": {
                                          "anim": {
                                              "enable": true,
                                              "speed": 1,
                                              "opacity_min": 0.05
                                          }
                                      }
                                  },
                                  "interactivity": {
                                      "events": {
                                          "onclick": {
                                              "enable": true,
                                              "mode": "push"
                                          }
                                      },
                                      "modes": {
                                          "push": {
                                              "particles_nb": 0.5
                                          }
                                      }
                                  },
                                  "retina_detect": true
                              }}                         
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundImage: `url(${bgimage}`}} />
                      </div>
                      <MDBMask overlay="black-light" className="flex-center flex-column text-white text-center">
                        <span className="block-example border border-white">
                        <h1  style={{fontWeight:"bold" , marginRight:"10px"}}> 
                        <FontAwesomeIcon icon={faHome} size="1x" style={{marginRight:"10px" , marginLeft:"10px"}} />
                           HOME</h1>
                        </span>
                    </MDBMask>
              </MDBView>
              
              <div class="container" style={{marginTop:'-30px', marginBottom:'50px', backgroundColor:"whitesmoke "}}>
                <div class="row justify-content-md-center">
                  <div class="col-md-12 info-panel" style={{backgroundColor:"whitesmoke ", height:"225px", marginTop:"5px"}}>
                    <div class="row text-center" style={{color:"black", fontFamily:"Raleway', sans-serif"}}>
                          <div class="col-lg" >
                              <h2 style={{marginTop:"5px"}}>Construction,<span> Mechanical Electrical</span> and General Trading</h2>
                              <h4>Office Workshop</h4>
                              <h6>JL Cimahi No 8 , RT 005 / RW 003 Desa Sukamahi, Cikarang Pusat , Bekasi , Jawa Barat , Indonesia 17530</h6>
                              <h6>Telp  : 021 2215 7933</h6>
                              <h6>Email : info@ont.co.id</h6>
                          </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="container">
                <div class="row justify-content-md-center">
                  <div class="col-md-12 info-panel">

                    <div class="row">
                      <div class="col-md">
                      <img src={Panel1} className="float-left" alt="logo" />
                        <h4>Chimney Modification</h4>
                        <p>PT ORI NUANSA TEKNOLOGI </p><br />
                      <div className="float-left"> 
                      <Link to="/" class="btn btn-primary tombol1">Our Work</Link>
                      </div>
                      </div>


                      <div class="col-md">
                      <img src={Panel2} className="float-left" alt="logo" />
                        <h4>Road Construction</h4>
                        <p>PT ORI NUANSA TEKNOLOGI </p><br />
                        <div className="float-left"> 
                        <Link to="/serv2" class="btn btn-primary tombol1">Our Work</Link>
                        </div>
                      </div>

                      <div class="col-md">
                      <img src={Panel3} className="float-left" alt="logo" />
                        <h4>Floor Construction</h4>
                        <p>PT ORI NUANSA TEKNOLOGI </p><br />
                        <Link to="/serv3" class="btn btn-primary tombol1">Our Work</Link>
                      </div>
                      <div class="col-md">
                      <img src={Panel4} className="float-left" alt="logo" />
                        <h4>ELECTRICAL AND SECURITY SYSTEM</h4>
                        <p>PT ORI NUANSA TEKNOLOGI</p><br />
                        <Link to="/serv4" class="btn btn-primary tombol1">Our Work</Link>
                      </div>
                    </div>
                  </div>
                  </div>
              </div>

              <Switch>
              <div class="container" style={{marginTop:"1px"}}>
                <div class="row justify-content-md-center">
                    <Route exact path="/" component={CardsPage1}/>
                    <Route exact path="/serv2" component={CardsPage2}/>
                    <Route exact path="/serv3" component={CardsPage3}/>
                    <Route exact path="/serv4" component={CardsPage4}/>
                </div>
              </div>
              </Switch>
              <div class="container" style={{marginTop:'50px', marginBottom:'50px'}}>
                <div class="row justify-content-md-center">
                  <div class="col-md-12 info-panel">
                    <div class="row workingspace">
                          <div class="col-lg-6">
                          <img src={ws} className="img-fluid" alt="logo" style={{height:'350px', width:'500px'}}/>
                          </div>

                          <div class="col-lg-5" >
                            <h3>Our <span>Quality</span> Objective <span></span></h3>
                            <p style={{fontSize:'15px'}}>With the culture work accelaration best quality and continuous improvement to achive customer satisfaction. </p>
                            <h3>Our <span>Mission</span><span></span></h3>
                            <p style={{fontSize:'15px'}}>To become a national pride company leading in service and performance</p>
                          </div>
                    </div>
                  </div>
                </div>
              </div>
    </Router>
        )
      }
}

export default JumHome;
