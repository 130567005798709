import React, { Component } from 'react';
import JumAbout from './JumAbout.js';

class About extends Component {
    render(){
        return(
            <div>
                <JumAbout/>
            </div>
        );
    };
};
export default About; 