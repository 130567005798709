import React, { Component } from 'react';
import './JumContact.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import bgimage from './Pic/Bg1.jpg';
import Particles from 'react-particles-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBMask, MDBView } from 'mdbreact';
import { faAddressCard} from '@fortawesome/free-solid-svg-icons';
import '../index.css';
import { MDBRow, MDBCol, MDBIcon, MDBBtn, MDBInput } from "mdbreact";


class JumContact extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: ''
  }

  handleChange = e => {
    const inputId = e.target.name;
    const value = e.target.value;
    this.setState({ [inputId]: value })
 }

    render(){
        return(
            <div>
            <MDBView>
                        <Particles 
                        params={{
                          particles: {
                            line_linked: {
                              shadow: {
                                enable: true,
                                color: "#FFFFFF",
                                blur: 5
                              }
                            }
                          }
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundImage: `url(${bgimage}`
                        }}
                      />
                      <MDBMask overlay="black-light" className="flex-center flex-column text-white text-center">
                        <span className="block-example border border-white">
                        <h1  style={{fontWeight:"bold" , marginRight:"10px"}}> 
                        <FontAwesomeIcon icon={faAddressCard} size="1x" style={{marginRight:"10px" , marginLeft:"10px"}} />
                           CONTACT US</h1>
                        </span>
                    </MDBMask>
              </MDBView>

            <div class="container" style={{marginTop:'-30px', marginBottom:'50px', backgroundColor:"whitesmoke"}}>
              <div class="row justify-content-md-center">
                <div class="col-md-12 info-panel">

                      <section className="my-5">
                          <h2 className="h1-responsive font-weight-bold text-center my-5">
                            Contact us
                          </h2>
                          <h5 className="text-center w-responsive mx-auto pb-5">
                              Please contact us via the available Email form or visit us according to the available Maps.
                              We would really appreciate your participation.
                          </h5>
                          <MDBRow>
                          <MDBCol md='5'>
                                <form action="mailto:info@ont.co.id" method="POST" encType="text/plain" className='hoverable' style={{ padding: 38 }}>
                                    <p className='h5 text-center mb-4'>Form Email</p>
                                    <div className='grey-text'>
                                        <MDBInput
                                            label='Your Name'
                                            name='name'
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                            icon='user'
                                            group
                                            type='text'
                                        />
                                        <MDBInput
                                            label='E-mail here'
                                            icon='envelope'
                                            name='email'
                                            group
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            type='email'
                                        />
                                        <MDBInput
                                            label='Subject'
                                            name='subject'
                                            icon='tag'
                                            group
                                            type='text'
                                            value={this.state.subject}
                                            onChange={this.handleChange}
                                        />
                                        <MDBInput
                                            type='textarea'
                                            rows='2'
                                            value={this.state.message}
                                            onChange={this.handleChange}
                                            name='message'
                                            label='Your message please...'
                                            icon='pencil-alt'
                                        />
                                    </div>
                                    <div className='text-center'>
                                        <MDBBtn outline color='secondary' type="submit">
                                            Send Now !!! <MDBIcon far icon='paper-plane' className='ml-1' />
                                        </MDBBtn>
                                    </div>
                                  </form>
                          </MDBCol>

                          <MDBCol lg="7">
                                    <div
                                      id="map-container"
                                      className="rounded z-depth-1-half map-container"
                                      style={{ height: "450px"}}
                                    >
                                      <iframe
                                        src="https://maps.google.com/maps?q=PT.%20ori%20nuansa%20teknologi&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                        title="This is a unique title"
                                        width="100%"
                                        height="100%"
                                        frameBorder="0"
                                        style={{ border: 0  }}
                                      />
                                    </div>
                                    <br />
                                    <MDBRow className="text-center">
                                      <MDBCol md="4">
                                        <MDBBtn tag="a" floating color="blue" className="accent-1">
                                          <MDBIcon icon="map-marker-alt" />
                                        </MDBBtn>
                                        <p className="mb-md-0 text-center">Bekasi, 17530</p>
                                        <p className="mb-md-0 text-center">Indonesia</p>
                                      </MDBCol>
                                      <MDBCol md="4">
                                        <MDBBtn tag="a" floating color="blue" className="accent-1">
                                          <MDBIcon icon="phone" />
                                        </MDBBtn>
                                        <p className="mb-md-0 text-center ">+62 21 2215 7933</p>
                                        <p className="mb-md-0 text-center ">Mon - Fri, 8:00-17:00</p>
                                        <p className="mb-md-0 text-center">Sat , 8:00-17:00</p>
                                        <p className="mb-md-0 text-center">Sun , Close</p>
                                      </MDBCol>
                                      <MDBCol md="4">
                                        <MDBBtn tag="a" floating color="blue" className="accent-1">
                                          <MDBIcon icon="envelope" />
                                        </MDBBtn>
                                        <p className="mb-md-0 text-center">info@ont.co.id</p>
                                      </MDBCol>
                                    </MDBRow>
                            </MDBCol>
                            </MDBRow>
                        </section>
                  </div>
                </div>
              </div>
        </div>
        )
    }
}
export default JumContact;