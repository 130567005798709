import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardGroup,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBContainer,
  MDBRow
} from 'mdbreact';
import Cimg1 from './Pic/Building/img1.jpeg';
import Cimg2 from './Pic/Building/img2.jpeg';
import Cimg3 from './Pic/Building/img3.jpeg';
import Cimg4 from './Pic/Building/img4.jpeg';
import Cimg5 from './Pic/Building/img5.jpeg';
import Cimg6 from './Pic/Building/img6.jpeg';
import './CardPage.css';
import Fade from 'react-reveal/Fade'; // Importing Fade effect



const CardsPage = () => {
  return (
    <MDBContainer class="Card-Image">
        <MDBCardGroup deck className='mt-3'>
        
        <MDBRow>
          <MDBCard>
          <Fade left>{/*Using Zoom Effect*/}
            <MDBCardImage
              src={Cimg1}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Chimney Modification</MDBCardTitle>
              <MDBCardText>
               Modification of the chimney assembly body plant PT Suzuki Motor Cikarang plant.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>{/*Using Zoom Effect*/}
            <MDBCardImage
              src={Cimg2}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Chimney Modification</MDBCardTitle>
              <MDBCardText>
              Modification of the chimney assembly body plant PT Suzuki Motor Cikarang plant.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
            <Fade right>
            <MDBCardImage
              src={Cimg3} 
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Chimney Modification</MDBCardTitle>
              <MDBCardText>
              Modification of the chimney assembly body plant PT Suzuki Motor Cikarang plant.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
          
        </MDBCardGroup>

        <MDBCardGroup deck className='mt-3'>
          <MDBRow>
          <MDBCard>
          <Fade left>
            <MDBCardImage
              src={Cimg4}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Chimney Modification</MDBCardTitle>
              <MDBCardText>
              Modification of the chimney assembly body plant PT Suzuki Motor Cikarang plant.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg5}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Chimney Modification</MDBCardTitle>
              <MDBCardText>
              Modification of the chimney assembly body plant PT Suzuki Motor Cikarang plant.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg6}
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Chimney Modification</MDBCardTitle>
              <MDBCardText>
              Modification of the chimney assembly body plant PT Suzuki Motor Cikarang plant.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>
    </MDBContainer>
  );
};
export default CardsPage;
