import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardGroup,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBContainer,
  MDBRow
} from 'mdbreact';
import Cimg1 from './Pic/Floor/img1.jpeg';
import Cimg2 from './Pic/Floor/img2.jpg';
import Cimg3 from './Pic/Floor/img3.jpeg';
import Cimg4 from './Pic/Floor/img4.jpg';
import Cimg5 from './Pic/Floor/img5.jpg';
import Cimg6 from './Pic/Floor/img6.jpg';
import Cimg7 from './Pic/Floor/img7.jpeg';
import Cimg8 from './Pic/Floor/img8.jpeg';
import Cimg9 from './Pic/Floor/img9.jpeg';
import Cimg10 from './Pic/Floor/img10.jpeg';
import Cimg11 from './Pic/Floor/img11.jpg';
import Cimg12 from './Pic/Floor/img12.jpeg';
import Cimg13 from './Pic/Floor/img13.jpg';
import Cimg14 from './Pic/Floor/img14.jpg';
import Cimg15 from './Pic/Floor/img15.jpg';
import Cimg16 from './Pic/Floor/img16.jpg';
import Cimg17 from './Pic/Floor/img17.jpeg';
import Cimg18 from './Pic/Floor/img18.jpeg';
import './CardPage.css';
import Fade from 'react-reveal/Fade'; // Importing Fade effect


const CardsPage = () => {
  return (
    <MDBContainer class="Card-Image">
      <MDBCardGroup deck className='mt-3'>
        <MDBRow>
          <MDBCard>
            <Fade left>
            <MDBCardImage
              src={Cimg18}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
                Loading Dock KD Export PT. Suzuki Indomobil Plan 2 Tambun.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg17}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Loading Dock KD Export PT. Suzuki Indomobil Plan 2 Tambun.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg16} 
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Void Closing Work & Escalator Mall Steel Strengthening Chadstone PT. POLLUX ADITAMA KENCANA.     
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>

      <MDBCardGroup deck className='mt-3'>
        <MDBRow>
          <MDBCard>
            <Fade left>
            <MDBCardImage
              src={Cimg13}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Void Closing Work & Escalator Mall Steel Strengthening Chadstone PT. POLLUX ADITAMA KENCANA.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg14}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Void Closing Work & Escalator Mall Steel Strengthening Chadstone PT. POLLUX ADITAMA KENCANA.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg15} 
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Void Closing Work & Escalator Mall Steel Strengthening Chadstone PT. POLLUX ADITAMA KENCANA.      
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>

        <MDBCardGroup deck className='mt-3'>
        <MDBRow>
          <MDBCard>
            <Fade left>
            <MDBCardImage
              src={Cimg1}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
                Project TPS non B3.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg2}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Project TPS non B3.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg3} 
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Project TPS non B3.       
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>

        <MDBCardGroup deck className='mt-3'>
          <MDBRow>
          <MDBCard>
          <Fade left>
            <MDBCardImage
              src={Cimg4}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Project TPS non B3.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg5}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Project TPS non B3.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg6}
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Project TPS non B3.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>

        <MDBCardGroup deck className='mt-3'>
          <MDBRow>
          <MDBCard>
          <Fade left>
            <MDBCardImage
              src={Cimg7}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Project TPS non B3.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg8}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
                Floor Injection.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg9}
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Floor Injection.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>
        
        <MDBCardGroup deck className='mt-3'>
          <MDBRow>
          <MDBCard>
          <Fade left>
            <MDBCardImage
              src={Cimg10}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Floor Injection.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg11}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Floor Injection.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg12}
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>Floor Cunstruction</MDBCardTitle>
              <MDBCardText>
              Floor Injection.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>
    </MDBContainer>
  );
};
export default CardsPage;
