import React from 'react';
import './App.css';
import Home from './Home/home.js';
import About from './About/about.js';
import Portofolio from './Portofolio/portofolio.js';
import Contact from './Contact/contact.js';
import {Route,Switch} from 'react-router-dom';
import NavBarMenu from './Componen/navbar.js';
import FooterPage from './Componen/FooterPage.js';


function App() {
  return (
    <div className="App">
    
        <NavBarMenu/>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/serv2" component={Home}/>
          <Route exact path="/serv3" component={Home}/>
          <Route exact path="/serv4" component={Home}/>
          <Route exact path="/about" component={About}/>
          <Route exact path="/ourclient" component={Portofolio}/>
          <Route exact path="/contact" component={Contact}/>
        </Switch>
        <FooterPage />
      
    </div>
  );
}

export default App;
