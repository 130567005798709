import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseUser, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import './FooterPage.css';

const FooterPage = () => {
  return (
    <div> 
    <MDBFooter color="unique-color-dark" className="page-footer font-small pt-0" >
      <div style={{ backgroundColor: "rgba(3, 102, 148, 0.8)" }}>
        <MDBContainer fluid className="text-center text-md-left">
          <MDBRow className="py-4 d-flex align-items-center" >
            <MDBCol md="6" lg="5" className="text-center text-md-left mb-4 mb-md-0">
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <MDBContainer className="mt-5 mb-4 text-center text-md-left">
        <MDBRow className="mt-3" style={{ backgroundColor: "#072739 " }}>
          <MDBCol md="3" lg="4" xl="3" className="mb-4">
            <h6 className="text-uppercase font-weight-bold">
              <strong>ORI NUANSA TEKNOLOGI</strong>
            </h6>
            <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
            <p>
            With the culture work accelaration best quality 
            and continuous improvement to achive customer satisfaction.
            </p>
          </MDBCol>
          <MDBCol md="2" lg="2" xl="2" className="mb-4">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Products</strong>
            </h6>
            <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
            <p>
              <a href="#!">Building Construction</a>
            </p>
            <p>
              <a href="#!">Engineering Construction</a>
            </p>
            <p>
              <a href="#!">Industry Construction</a>
            </p>
            <p>
              <a href="#!">Electrical and Security System</a>
            </p>
          </MDBCol>
          
          <MDBCol md="3" lg="2" xl="4" className="mb-5">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Contact</strong>
            </h6>
            <div>
            <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "80px" }} />
            <p>
            <FontAwesomeIcon icon={faHouseUser} size="1x" />
              <i className="text-md-left" />  JL Cimahi No 8 ,RT 005 / RW 003 Desa Sukamahi, Cikarang Pusat, Bekasi, Jawa Barat, Indonesia 17530
            </p>
            <p>
            <FontAwesomeIcon icon={faEnvelope} size="1x" />
              <a href="#!"><i className="text-md-left" />  info@ont.co.id</a>
            </p>
            <p>
                <FontAwesomeIcon icon={faPhone} size="1x" />
              <i className="text-md-left" /> Phone : + 62 21 2215 7933
            </p>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-10">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: <a href="#!">PT. Ori Nuansa Teknologi</a>
        </MDBContainer>
      </div>
    </MDBFooter>
    </div>
  );
}

export default FooterPage;
