import React, { Component } from 'react';
import JumPorto from './JumPorto.js';

class Portofolio extends Component {
    render(){
        return(
            <div>
            <JumPorto/>
            </div>
        )
    }
}
export default Portofolio; 