import React from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, 
  MDBCollapse, MDBNavItem, MDBNavLink} from 'mdbreact';
import Logo from './logo.png';
import './navbar.css';

class NavBarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }
  render() {
    return (
      <div>
        <header>
            <MDBNavbar scrolling transparent color="unique-color" fixed="top" dark expand="lg" >
              
              <MDBNavbarBrand href="/">
              <img src={Logo} className="rounded float-left fa-lg" alt="aligment" style={{width: "60px"}} />
              </MDBNavbarBrand>
              <MDBNavbarBrand href="/" className="master-text">
                PT ORI NUANSA TEKNOLOGI
              </MDBNavbarBrand>
              {!this.state.isWideEnough && <MDBNavbarToggler onClick={this.onClick} />}
              <MDBCollapse isOpen={this.state.collapse} navbar>
                <MDBNavbarNav right className="text-uppercase nav-text">
                  <MDBNavItem  active>
                    <MDBNavLink to="/">HOME</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/about">ABOUT US</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/ourclient">OUR CLIENT</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="/contact">CONTACT US</MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
        </header>
      </div>
    );
  }
}

export default NavBarMenu;