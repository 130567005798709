import React, { Component } from 'react';
import JumHome from './JumHome.js';

class Home extends Component {
    render(){
        return(
            <div>
                <JumHome/>
            </div>
        )
    }
}
export default Home; 