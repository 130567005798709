import React, { Component } from 'react';
import './JumPorto.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import bgimage from './Pic/Bg1.jpg';
import Particles from 'react-particles-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBMask, MDBView, MDBListGroup, MDBListGroupItem, MDBContainer } from 'mdbreact';
import { faPeopleArrows} from '@fortawesome/free-solid-svg-icons';
import '../index.css';

class JumPorto extends Component {
    render(){
        return(
            <div>
            <header className="App">
            <MDBView>
                        <Particles 
                        params={{
                          particles: {
                            line_linked: {
                              shadow: {
                                enable: true,
                                color: "#3CA9D1",
                                blur: 5
                              }
                            }
                          }
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundImage: `url(${bgimage}`
                        }}
                      />
                      <MDBMask overlay="black-light" className="flex-center flex-column text-white text-center">
                        <span className="block-example border border-white">
                        <h1  style={{fontWeight:"bold" , marginRight:"10px" , color:"white"}}> 
                        <FontAwesomeIcon icon={faPeopleArrows} size="1x" style={{marginRight:"10px" , marginLeft:"10px"}} />
                           OUR CLIENT</h1>
                        </span>
                    </MDBMask>
              </MDBView>
            <div class="container" style={{marginTop:'-30px', marginBottom:'50px', backgroundColor:"whitesmoke"}}>
              <div class="row justify-content-md-center">
                <div class="col-md-12 info-panel">
                      <MDBContainer style={{marginTop:'20px', marginBottom:'20px'}}>
                        <MDBListGroup>
                          <MDBListGroupItem>
                          <img src="https://suzuki-indomobil.com/wp-content/uploads/2019/07/cropped-logo-suzuki-indomobil-2.png" className="rounded float-left img-fluid z-dpth-1" style={{height:'30px', width:'50px'}} alt="aligment"/>
                            PT. Suzuki Indomobil Motor</MDBListGroupItem>
                          <MDBListGroupItem>
                          <img src="https://lh5.googleusercontent.com/-UDULzU9Uvz4/AAAAAAAAAAI/AAAAAAAAAAA/LuTa-69SiB8/s44-p-k-no-ns-nd/photo.jpg" className="rounded float-left img-fluid z-depth-1" style={{height:'20px', width:'20px'}} alt="aligment"/>
                            PT. Marugo Rubber Indonesia</MDBListGroupItem>
                          <MDBListGroupItem>
                          <img src="https://www.waskita.co.id/img/logo/wk.png" className="rounded float-left img-fluid z-depth-1" style={{height:'20px', width:'25px'}} alt="aligment"/>
                            PT. Waskita Karya</MDBListGroupItem>
                          <MDBListGroupItem>
                          <img src="https://www.wika.co.id/po-content/wika/assets/images/logo.png" className="rounded float-left img-fluid z-depth-1" style={{height:'20px', width:'25px'}} alt="aligment"/>
                            PT. Wijaya Karya (Project HSRCC)</MDBListGroupItem>
                          <MDBListGroupItem>
                          <img src="https://randugarutplastic.com/images/logo.png" className="rounded float-left img-fluid z-depth-1" style={{height:'20px', width:'25px'}} alt="aligment"/>
                            PT. Randugarut Plastic Indonesia </MDBListGroupItem>
                          <MDBListGroupItem>
                          <img src="https://www.yutaka.co.id/img/Logo.png" className="rounded float-left img-fluid z-depth-1" style={{height:'20px', width:'25px'}} alt="aligment"/>
                            PT. Yutaka Manufacturing Indonesia </MDBListGroupItem>
                            <MDBListGroupItem>
                          <img src="https://static.wixstatic.com/media/2540d7_3f5bdd2632d9406e8cdb5d897d31e7fc~mv2.jpg/v1/crop/x_0,y_122,w_716,h_392/fill/w_173,h_86,al_c,q_80,usm_0.66_1.00_0.01/2540d7_3f5bdd2632d9406e8cdb5d897d31e7fc~mv2.webp" className="rounded float-left img-fluid z-depth-1" style={{height:'20px', width:'25px'}} alt=""/>
                            PT. Daehan Global Indonesia </MDBListGroupItem>
                        </MDBListGroup>
                      </MDBContainer>
                </div>
              </div>
            </div>
        </header> 
        </div>
        )
    }
}
export default JumPorto;