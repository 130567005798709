import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardGroup,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBContainer,
  MDBRow
} from 'mdbreact';
import Cimg1 from './Pic/Alarm/img1.jpg';
import Cimg2 from './Pic/Alarm/img2.jpg';
import Cimg3 from './Pic/Alarm/img3.jpg';
import Cimg4 from './Pic/Alarm/img4.jpg';
import Cimg5 from './Pic/Alarm/img5.jpg';
import Cimg6 from './Pic/Alarm/img6.jpg';
import Cimg7 from './Pic/Alarm/img7.jpg';
import Cimg8 from './Pic/Alarm/img8.jpg';
import Cimg9 from './Pic/Alarm/img9.jpg';
import Cimg10 from './Pic/Alarm/img10.jpg';
import Cimg11 from './Pic/Alarm/img11.jpg';
import Cimg12 from './Pic/Alarm/img12.jpg';
import Cimg13 from './Pic/Alarm/img13.jpeg';
import Cimg14 from './Pic/Alarm/img14.jpeg';
import Cimg15 from './Pic/Alarm/img15.jpeg';
import Cimg16 from './Pic/Alarm/img16.jpeg';
import Cimg17 from './Pic/Alarm/img17.jpeg';
import Cimg18 from './Pic/Alarm/img18.jpeg';
import './CardPage.css';
import Fade from 'react-reveal/Fade'; // Importing Fade effect


const CardsPage = () => {
  return (
    <MDBContainer class="Card-Image">
            <MDBCardGroup deck className='mt-3'>
        <MDBRow>
          <MDBCard>
            <Fade left>
            <MDBCardImage
              src={Cimg16}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
                Fire Prevention Installation PT COMETIC PERKASA ABADI.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg17}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
              Fire Prevention Installation PT COMETIC PERKASA ABADI.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg18} 
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
              Fire Prevention Installation PT COMETIC PERKASA ABADI.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>

            <MDBCardGroup deck className='mt-3'>
        <MDBRow>
          <MDBCard>
            <Fade left>
            <MDBCardImage
              src={Cimg13}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
              Fire Prevention Installation PT COMETIC PERKASA ABADI.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg14}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
              Fire Prevention Installation PT COMETIC PERKASA ABADI.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg15} 
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
              Fire Prevention Installation PT COMETIC PERKASA ABADI.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>

      <MDBCardGroup deck className='mt-3'>
        <MDBRow>
          <MDBCard>
            <Fade left>
            <MDBCardImage
              src={Cimg10}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
                Fire Prevention Installation PT COMETIC PERKASA ABADI.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg11}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
                Solar Roof Fan Installation PT. YUTAKA MANUFACTURING INDONESIA.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg12} 
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
                Solar Roof Fan Installation PT. YUTAKA MANUFACTURING INDONESIA.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>

        <MDBCardGroup deck className='mt-3'>
        <MDBRow>
          <MDBCard>
            <Fade left>
            <MDBCardImage
              src={Cimg1}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
                Installation and Maintenenca Fire Alarm System.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg2}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
              Installation and Maintenenca Fire Alarm System.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg3} 
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
              Installation and Maintenenca Fire Alarm System.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>

        <MDBCardGroup deck className='mt-3'>
          <MDBRow>
          <MDBCard>
          <Fade left>
            <MDBCardImage
              src={Cimg4}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
                Installation Fire Suppression System.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg5}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
              Installation Fire Suppression System.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg6}
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
              Installation Fire Suppression System.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>

        <MDBCardGroup deck className='mt-3'>
          <MDBRow>
          <MDBCard>
          <Fade left>
            <MDBCardImage
              src={Cimg7}
              alt='MDBCard image cap'
              top
              hover
              overlay='yellow-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
                Installation Hydrant & Fire Alarm System PT. Randugarut Plastic Indonesia.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade bottom>
            <MDBCardImage
              src={Cimg8}
              alt='MDBCard image cap'
              top
              hover
              overlay='red-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
              Installation Hydrant & Fire Alarm System PT. Randugarut Plastic Indonesia.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>

          <MDBCard>
          <Fade right>
            <MDBCardImage
              src={Cimg9}
              alt='MDBCard image cap'
              top
              hover
              overlay='blue-light'
            />
            <MDBCardBody>
              <MDBCardTitle tag='h5'>ELECTRICAL AND SECURITY SYSTEM</MDBCardTitle>
              <MDBCardText>
              Installation Hydrant & Fire Alarm System PT. Randugarut Plastic Indonesia.
              </MDBCardText>
            </MDBCardBody>
            </Fade>
          </MDBCard>
          </MDBRow>
        </MDBCardGroup>
    </MDBContainer>
  );
};
export default CardsPage;
